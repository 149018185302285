/* ./src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


/* Custom Keyframes for Additional Animations */
@keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes jiggle {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(5deg); }
    50% { transform: rotate(-5deg); }
    75% { transform: rotate(2deg); }
    100% { transform: rotate(0deg); }
  }
  
  @keyframes float {
    0%, 100% { transform: translatey(0); }
    50% { transform: translatey(-10px); }
  }
  
  @keyframes swing {
    0% { transform: rotate(0deg); }
    20% { transform: rotate(15deg); }
    40% { transform: rotate(-10deg); }
    60% { transform: rotate(5deg); }
    100% { transform: rotate(0deg); }
  }
  
  /* Register custom animations */
  .fade-in {
    animation: fade-in 1s ease-in forwards;
  }
  
  .jiggle {
    animation: jiggle 1s ease-in-out infinite;
  }
  
  .float {
    animation: float 3s ease-in-out infinite;
  }
  
  .swing {
    animation: swing 1s ease-in-out infinite;
  }